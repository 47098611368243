import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import ScheduleLink from 'pages/provider/schedule/components/schedule-link/ScheduleLink';
import Header from 'components/v2/Header';

import { FormikErrors } from 'formik';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import ImportFromCAQH from './components/forms/import-from-caqh';
import SubmitDrawer from './components/drawers/SubmitDrawer';
import ImportDrawer from './components/drawers/ImportDrawer';

import {
  dataLabels,
  emptyContactInformation,
  emptyGeneralInformation,
  emptyHospitalAffiliation,
  emptyName,
  emptyPracticeLocation,
  emptyReference,
  emptyWorkHistory,
  sectionDescriptions,
  sectionTitles,
  SectionNames,
} from './index.constants';
import FormList from '../common/forms/form-list';
import {
  caqhDataSummary,
  extractCredentialingData,
  fetchCaqhCredentials,
  putProviderData,
} from '../../../../services/datalab/datalab.service';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import { camelCaseToText, sortByRef, transformData } from '../common/common.utils';
import { CAQHData, DblDrawerData } from '../common/index.types';

const CAQHInformation: FC = () => {
  const [showNewUpload, setShowNewUpload] = useState(false);
  const [isImportDisabled, setIsImportDisabled] = useState(true);
  const [isInProgess, setIsInProgess] = useState(false);
  const [caqhStepStatus, setCaqhStepStatus] = useState({
    data: '',
    step: '',
    status: '',
    message: '',
  });
  const [importFailed, setImportFailed] = useState(false);
  const [showAddManually, setShowAddManually] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [type, setType] = useState<SectionNames | undefined>(undefined);
  const [submitList, setSubmitList] = useState<DblDrawerData>({
    filled: [],
    missing: [],
    list: [],
    title: undefined,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [caqhData, setCaqhData] = useState<CAQHData>(null);
  const dispatch = useDispatch();
  const { meta } = useSelector(getAuth);
  const navigate = useNavigate();

  const getDataSection = (sectionType: string): Promise<AxiosResponse<any, any>> => {
    return caqhDataSummary({
      type: sectionType,
      providerId: meta?.userId,
    });
  };

  const getCaqhDataSummary = async () => {
    try {
      const { data } = await getDataSection('general-information');
      setCaqhData(data?.data);
    } catch (err) {
      const msg = err.response?.data?.errors?.[0]?.endUserMessage || err.message || 'Something went wrong';
    }
  };

  const getStatus = async () => {
    try {
      const { data } = await fetchCaqhCredentials({ userAccountId: meta?.userId });
      setCaqhStepStatus({
        data: data.data,
        step: data.data?.caqhImportStatus?.step ?? '',
        status: data.data?.caqhImportStatus?.status ?? '',
        message: data.data?.caqhImportStatus?.message ?? '',
      });
      if (!data.data && !data.success) {
        setIsImportDisabled(false);
      }
    } catch (e) {
      const msg = e.response?.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    }
  };

  useEffect(() => {
    void getStatus();
    void getCaqhDataSummary();
  }, []);

  useEffect(() => {
    if (caqhStepStatus.step !== '') {
      if (caqhStepStatus.step === 'validateCAQHCredentials') {
        if (caqhStepStatus.status === 'COMPLETED') {
          setIsImportDisabled(false);
          setImportFailed(false);
          setIsInProgess(false);
        } else if (caqhStepStatus.status !== 'COMPLETED') {
          if (caqhStepStatus.status === 'IN PROGRESS') {
            setImportFailed(false);
            setIsImportDisabled(true);
            setIsInProgess(true);
          }
          if (caqhStepStatus.status === 'FAILED') {
            setIsImportDisabled(false);
            setIsInProgess(false);
            setImportFailed(true);
          }
        }
      } else if (caqhStepStatus.step === 'documentsDownload') {
        if (caqhStepStatus.status === 'FAILED') {
          setIsImportDisabled(false);
          setIsInProgess(false);
          setImportFailed(true);
        } else {
          setIsInProgess(true);
          setIsImportDisabled(true);
          setImportFailed(false);
        }
      } else if (caqhStepStatus.step === 'gatherCAQHData') {
        if (caqhStepStatus.status === 'FAILED' || caqhStepStatus.status === 'COMPLETED') {
          setIsImportDisabled(false);
          setIsInProgess(false);
          if (caqhStepStatus.status === 'FAILED') {
            setImportFailed(true);
          } else {
            setImportFailed(false);
          }
        } else {
          setIsImportDisabled(true);
          setIsInProgess(true);
          setImportFailed(false);
        }
      }
    }
  }, [caqhStepStatus]);

  const onAddArrayItem = (
    sectionKey: string,
    index: number,
    position: number,
    setValues: (
      values: React.SetStateAction<any>,
      shouldValidate?: boolean,
    ) => Promise<FormikErrors<any>> | Promise<void>,
  ) => {
    const itemList = [];
    const section = {
      section: true,
      key: sectionKey,
      label: `${camelCaseToText(sectionKey)?.toUpperCase()} ${index + 1}`,
    };
    itemList.push(section);
    const labelKey = sectionKey.replaceAll('-', '.').replace(/(\d+)\./g, '');
    Object.keys(dataLabels)
      .filter(key => key.startsWith(labelKey))
      .forEach(key => {
        const item = {
          key: `${sectionKey}-${index + 1}-${key
            .replaceAll('.', '-')
            .replaceAll(`${labelKey.replaceAll('.', '-')}-`, '')}`,
          label: dataLabels[key],
          value: '',
        };
        itemList.push(item);
      });
    const newMissing = [...submitList.missing];
    newMissing.splice(position, 0, ...itemList);
    const itemOb = itemList
      .filter(item => item.key && !item.section)
      .reduce((acc, curr) => {
        if (curr.key) {
          if (curr.badge?.label) {
            acc[curr.key] = curr.badge?.label || '';
          } else {
            acc[curr.key] = curr.value !== undefined && curr.value !== null ? curr.value : '';
          }
        }
        return acc;
      }, {});
    void setValues(values => {
      return {
        ...values,
        ...itemOb,
      };
    });
    setSubmitList(ex => ({
      ...ex,
      missing: newMissing,
    }));
  };

  const handleEdit = useCallback((section, list: DblDrawerData, edit = true) => {
    return () => {
      setShowAddManually(true);
      setType(section);
      sortByRef(list, section);
      setSubmitList(list);
      setEditMode(edit);
    };
  }, []);

  const memoizedTransformData = useMemo(() => {
    return {
      providerNamesList: transformData(caqhData?.providerNames, false, '', SectionNames.NAME),
      generalInformationList: transformData(
        caqhData?.generalInformation,
        false,
        '',
        SectionNames.GENERAL_INFORMATION,
      ),
      contactInformationList: transformData(
        caqhData?.contactInformation,
        false,
        '',
        SectionNames.CONTACT_INFORMATION,
      ),
      practiceLocations: transformData(
        caqhData?.practiceLocations,
        false,
        '',
        SectionNames.PRACTICE_LOCATIONS,
      ),
      referencesInformation: transformData(
        caqhData?.referencesInformation,
        false,
        '',
        SectionNames.REFERENCES_INFORMATION,
      ),
      hospitalAffiliations: transformData(
        caqhData?.hospitalAffiliations,
        false,
        '',
        SectionNames.HOSPITAL_AFFILIATIONS,
      ),
      workHistoryInformation: transformData(
        caqhData?.workHistoryInformation,
        false,
        '',
        SectionNames.WORK_HISTORY_INFORMATION,
      ),
    };
  }, [caqhData]);

  const {
    providerNamesList,
    generalInformationList,
    contactInformationList,
    practiceLocations,
    referencesInformation,
    hospitalAffiliations,
    workHistoryInformation,
  } = memoizedTransformData;

  const resetInformation = () => {
    setSubmitList({ filled: [], missing: [], list: [], title: undefined });
  };

  const onCloseModal = () => {
    setShowAddManually(false);
    setShowNewUpload(false);
    resetInformation();
  };

  const importDataFromCAQH = async () => {
    try {
      setIsImportDisabled(true);
      setIsInProgess(true);
      const response = await extractCredentialingData({ userAccountId: meta?.userId });
    } catch (e) {
      setImportFailed(true);
      console.warn(e);
    }
  };

  const payload = (obj: any, dataType: string) => {
    return {
      providerId: meta.userId,
      data: {
        dataType,
        ...obj,
      },
    };
  };

  const getSectionName = (sectionType: SectionNames) => {
    switch (sectionType) {
      case SectionNames.NAME:
      case SectionNames.GENERAL_INFORMATION:
      case SectionNames.CONTACT_INFORMATION:
        return 'personal-information';
      default:
        return sectionType;
    }
  };

  const onFormSubmit = async (data: any, edit: boolean) => {
    setLoading(true);
    try {
      let transformedData: any;
      if (type === SectionNames.PRACTICE_LOCATIONS) {
        transformedData = data.activeLocation;
      } else {
        transformedData = data;
      }
      // if (edit) {
      const { data: response } = await putProviderData(getSectionName(type), payload(transformedData, type));
      if (!response.success) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: response.error,
          }),
        );
      } else {
        setShowAddManually(false);
        setType(null);
        setSubmitList({ filled: [], missing: [], list: [], title: undefined });
        void getCaqhDataSummary();
      }
      // } else {
      //   console.log('Add data');
      // }
    } catch (e) {
      console.warn(e);
      if (e.data) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: e.data.error,
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: e.message || 'Something went wrong',
          }),
        );
      }
    }
    setLoading(false);
  };

  const isShowImportCAQH = caqhStepStatus.step === 'gatherCAQHData' && caqhStepStatus.status === 'COMPLETED';

  return (
    <>
      <Header label="CAQH information" />
      <Box display="flex" flexDirection="column" gap={3}>
        {(!isShowImportCAQH || !caqhStepStatus.data) && (
          <ImportFromCAQH
            caqhStepStatus={caqhStepStatus.data}
            isImportDisabled={isImportDisabled}
            importFailed={importFailed}
            onClickImport={() => {
              if (!caqhStepStatus.data) {
                navigate('/provider/profile?name=CAQHAccess');
              } else {
                void importDataFromCAQH();
                setImportFailed(false);
              }
            }}
            isInProgess={isInProgess}
          />
        )}
        <FormList
          title="Name"
          onEdit={handleEdit(SectionNames.NAME, providerNamesList)}
          list={providerNamesList.list}
          onAddManually={() => {
            const data = transformData(emptyName, true, '', SectionNames.NAME);
            handleEdit(SectionNames.NAME, data, true)();
          }}
        />
        <FormList
          title="Contact information"
          onEdit={handleEdit(SectionNames.CONTACT_INFORMATION, contactInformationList)}
          list={contactInformationList.list}
          onAddManually={() => {
            const data = transformData(emptyContactInformation, true, '', SectionNames.CONTACT_INFORMATION);
            handleEdit(SectionNames.CONTACT_INFORMATION, data, true)();
          }}
        />
        <FormList
          title="General Information"
          onEdit={handleEdit(SectionNames.GENERAL_INFORMATION, generalInformationList)}
          list={generalInformationList.list}
          onAddManually={() => {
            const data = transformData(emptyGeneralInformation, true, '', SectionNames.GENERAL_INFORMATION);
            handleEdit(SectionNames.GENERAL_INFORMATION, data, true)();
          }}
        />
        <FormList
          title="Practice locations"
          list={practiceLocations.list}
          onClickViewInfo={list => handleEdit(SectionNames.PRACTICE_LOCATIONS, list, false)()}
          onAddManually={() => {
            const data = transformData(
              emptyPracticeLocation,
              true,
              'activeLocation',
              SectionNames.PRACTICE_LOCATIONS,
            );
            handleEdit(SectionNames.PRACTICE_LOCATIONS, data, true)();
          }}
        />
        <FormList
          title="Hospital affiliations"
          onEdit={handleEdit(SectionNames.HOSPITAL_AFFILIATIONS, hospitalAffiliations)}
          list={hospitalAffiliations.list}
          onAddManually={() => {
            const data = transformData(
              emptyHospitalAffiliation,
              true,
              '',
              SectionNames.HOSPITAL_AFFILIATIONS,
            );
            handleEdit(SectionNames.HOSPITAL_AFFILIATIONS, data, true)();
          }}
        />
        <FormList
          title="Work history information"
          onEdit={handleEdit(SectionNames.WORK_HISTORY_INFORMATION, workHistoryInformation)}
          list={workHistoryInformation.list}
          onAddManually={() => {
            const data = transformData(emptyWorkHistory, true, '', SectionNames.WORK_HISTORY_INFORMATION);
            handleEdit(SectionNames.WORK_HISTORY_INFORMATION, data, true)();
          }}
        />
        <FormList
          title="References information"
          onEdit={handleEdit(SectionNames.REFERENCES_INFORMATION, referencesInformation)}
          list={referencesInformation.list}
          onAddManually={() => {
            const data = transformData(emptyReference, true, '', SectionNames.REFERENCES_INFORMATION);
            handleEdit(SectionNames.REFERENCES_INFORMATION, data, true)();
          }}
        />
        {showNewUpload && type && (
          <ImportDrawer open={showNewUpload} type={type} onClose={() => setShowNewUpload(false)} />
        )}
        {showAddManually && type && (
          <SubmitDrawer
            missedKeys={submitList.missing}
            filledKeys={submitList.filled}
            open={showAddManually}
            title={submitList.title || sectionTitles[type]}
            subTitle={sectionDescriptions[type]}
            onClose={onCloseModal}
            list={submitList.list}
            onEdit={() => {
              setEditMode(true);
            }}
            loading={loading}
            editMode={editMode}
            onAddArrayItem={onAddArrayItem}
            onFormSubmit={onFormSubmit}
          />
        )}
      </Box>
    </>
  );
};

export default CAQHInformation;
