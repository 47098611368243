import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { AlertColor, Box } from '@mui/material';
import cx from 'clsx';
import { colors } from '@confidant-health/lib/colors';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { fontWeight, Heading, headingLevel, Text } from '@confidant-health/lib/ui/atoms/typography';
import { IAppointment } from '@confidant-health/lib/ui/templates/appointment-card';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import ToastMessage from '@confidant-health/lib/ui/templates/toast-message';
import { OTPublisher, OTSession, OTStreams, OTSubscriber } from 'opentok-react';
import {
  updateAppointmentEvaluationStatus,
  updateEvaluationContext,
  updateContextForFullPageDCT,
} from 'services/conversation/conversation.service';
import { OPENTOK_APIKEY, TOAST_TYPES } from 'constants/CommonConstants';
import AddSchedule, { INewSchedulePayload } from 'pages/admin/appointments/add-schedule';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { useDispatch, useSelector } from 'react-redux';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import Priorities from '../../priorities';
import TopbarView from './components/topbar-view';
import BottomView from './components/bottom-view';
import Chats from './components/chats';
import Checklist from './components/checklist';
import EvaluationCompleted from '../evaluations/evaluation-section-detail/components/evaluation-completed';
import { ISource } from './components/share-screen-selection/ShareScreenSelection';
import { FilterSessions } from '../session-filters/SessionFilters.constants';
import { useStyles } from './VideoCallScreen.styles';
import WaitingHeader from '../waiting-header';
import AppointmentInfo from '../appointment-info';
import { IEvaluationItem, ISessionFilterObject } from '../evaluations-v2/Evaluations.types';
import { RouteState } from '../evaluations-v2/Evaluations.constants';
import EvaluationRejected from '../evaluations/evaluation-section-detail/components/evaluation-rejected';
import { InPersonSession } from '../in-person-session/InPersonSession';
import useEvaluationHead, { ProvideEvaluationHead } from '../../../../hooks/useEvaluationHead';
import { selectEvaluationContext } from '../../../../redux/modules/conversation/selectors';
import { conversationActionCreators } from '../../../../redux/modules/conversation';
import { EvaluationItemNode } from '../../../../redux/modules/conversation/types';
import { showSnackbar } from '../../../../redux/modules/snackbar';
import { EvaluationHelper } from '../../../../utils/EvaluationHelper';
import { EvaluationsV2 } from '../evaluations-v2/evaluation-left-section/EvaluationsV2';
import EvaluationDetail from '../evaluations-v2/evaluation-left-section/evaluation-detail';
import EvaluationReview from '../evaluations/evaluation-section-detail/components/evaluation-review';
import { LeaveUnansweredModal } from '../evaluations-v2/evaluation-modals/LeaveUnansweredModal';
import { EvaluationRightSection } from '../evaluations-v2/evaluation-right-section';

type Props = {
  isWaiting: boolean;
  setIsWaiting: Dispatch<SetStateAction<boolean>>;
  appointment: IAppointment;
  isEndedSession: boolean;
  selectedSession: string;
  onEndSession: () => void;
  setShowSignOff: () => void;
  setIsEndedSession: Dispatch<SetStateAction<boolean>>;
  chatsDrawerOpen: boolean;
  setChatsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  onCloseSession: () => void;
  onChangeView: (view: string) => void;
  telesessionToken: string;
  telesessionId: string;
  onStartSession: (val: boolean) => void;
  sessionFilterList: Array<ISessionFilterObject>;
  careTeamListIds: Array<string>;
  getEvaluationProgressList: () => void;
  isInPersonSessionCall?: boolean;
  startInPersonSession?: () => void;
  showInPersonSession?: boolean;
  initialEvaluation: IEvaluationItem;
  externalSelectedEvaluationId?: string;
  isSignOff?: boolean;
};

const list = new EvaluationHelper();
const VideoCallScreen: FC<Props> = ({
  isWaiting,
  setIsWaiting,
  appointment,
  isEndedSession,
  selectedSession,
  onEndSession,
  setShowSignOff,
  setIsEndedSession,
  chatsDrawerOpen,
  setChatsDrawerOpen,
  onCloseSession,
  onChangeView,
  telesessionToken,
  telesessionId,
  onStartSession,
  sessionFilterList,
  careTeamListIds,
  getEvaluationProgressList,
  isInPersonSessionCall,
  startInPersonSession,
  showInPersonSession,
  initialEvaluation,
  isSignOff = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const providerSchedule = useSelector(selectProviderSchedule);
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [schedulesDrawerOpen, setSchedulesDrawerOpen] = useState(false);
  const [publishVideo, setPublishVideo] = useState(true);
  const [publishAudio, setPublishAudio] = useState(true);
  const [showShareScreenSelection, setShowShareScreenSelection] = useState(false);
  const [selectedScreenSharing] = useState<ISource | null>(null);
  const [, setSelectedEvaluationSectionItem] = useState<any | null>(null);
  const [showCompletedEvaluation, setShowCompletedEvaluation] = useState(false);
  const [memberVideoEnable, setMemberVideoEnable] = useState(true);
  const [sessionStarted, setSessionStarted] = useState(false);
  const isSessionEndedRef = useRef(false);
  const [toastProperties, setToastProperties] = useState({
    message: '',
    type: TOAST_TYPES.SUCCESS,
    status: false,
  });
  // const [notes, setNotes] = useState('');

  const [currentEvaluation, setCurrentEvaluation] = useState<IEvaluationItem>(null);
  const [currentBlock, setCurrentBlock] = useState<EvaluationItemNode>(null);
  const [routeState, setRouteState] = useState<RouteState>(RouteState.INITIAL);
  const [nextInProgress, setNextInProgress] = useState(false);
  const [showAddEvaluation, setShowAddEvaluation] = useState(false);
  const [selectedEvaluationItem, setSelectedEvaluationItem] = useState<any | null>(null); // useState<IEvaluationItem | null>(null);
  const [showEvaluationReview, setShowEvaluationReview] = useState(false);
  const [showRejectedEvaluation, setShowRejectedEvaluation] = useState(false);
  const [showLeaveUnansweredModal, setShowLeaveUnansweredModal] = useState(false);
  const [showLeaveUnansweredDCTModal, setShowLeaveUnansweredDCTModal] = useState(false);
  // const [isEvaluationComplete, setIsEvaluationComplete] = useState(false);
  const [textInputInFocus, setTextInputInFocus] = useState(false);
  const [leftTrigger, setLeftTrigger] = useState(false);
  const [rightTrigger, setRightTrigger] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [focusedChildIndex, setFocusedChildIndex] = useState(0);
  const [isHotkeysModalOpen, setIsHotkeysModalOpen] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const { head, processEvaluationData, updateValues } = useEvaluationHead();
  const evaluationContext = useSelector(selectEvaluationContext);

  useEffect(() => {
    if (currentEvaluation) {
      dispatch(
        conversationActionCreators.fetchEvaluationContext({
          appointmentId: appointment?.appointmentId,
          evaluationId: currentEvaluation.id,
        }),
      );
    }
    // Cleanup function
    return () => {
      dispatch(
        conversationActionCreators.evaluationContextUpdated({
          evaluationContext: null,
        }),
      );
    };
  }, [currentEvaluation]);

  const evaluationSelected = evaluation => {
    if (evaluation?.status === 'COMPLETED') setShowCompletedEvaluation(true);
    else setShowCompletedEvaluation(false);
    setCurrentBlock(null);
    setCurrentEvaluation(evaluation);
    setSelectedEvaluationItem(evaluation);
    setRouteState(RouteState.IN_PROGRESS);
  };

  useEffect(() => {
    if (initialEvaluation) {
      evaluationSelected(initialEvaluation);
    }
  }, [initialEvaluation]);

  const completeEvaluation = () => {
    if (showEvaluationReview) {
      void updateEvaluationStatus();
      setShowEvaluationReview(false);
      setShowCompletedEvaluation(true);
    } else {
      setShowEvaluationReview(true);
    }
  };

  const setNextBlock = (block, context, forceNext = false) => {
    if (block) {
      if (nextInProgress || forceNext) {
        const next = list.getNextApplicableBlock(block.cbId);
        if (next) {
          setCurrentBlock(next);
          setSelectedIndex(-1);
          setFocusedChildIndex(0);
        } else if (forceNext) {
          setShowEvaluationReview(true);
        } else {
          completeEvaluation();
        }
        setNextInProgress(false);
      } else {
        setCurrentBlock(list.getContentBlock(block.cbId));
      }
    } else {
      setCurrentBlock(context);
      setSelectedIndex(-1);
      setFocusedChildIndex(0);
    }
  };

  const setPreviousBlock = block => {
    if (block) {
      const prev = list.getPreviousApplicableBlock(block.cbId);
      if (prev) {
        if (showEvaluationReview) {
          setShowEvaluationReview(false);
        }
        setCurrentBlock(prev);
        setSelectedIndex(-1);
        setFocusedChildIndex(0);
      }
    }
  };

  useEffect(() => {
    if (evaluationContext) {
      processEvaluationData(evaluationContext);
      if (evaluationContext.evaluationId === selectedEvaluationItem?.id) {
        const cbResponse = getQuestionSectionsByType(evaluationContext.cbResponseList);
        selectedEvaluationItem.sections = cbResponse;
        setSelectedEvaluationItem(selectedEvaluationItem);
      }
    }
  }, [evaluationContext]);

  useEffect(() => {
    if (head) {
      setNextBlock(currentBlock, head);
    }
  }, [head]);

  const submitAnswer = async () => {
    if (currentBlock && currentBlock.userResponse) {
      const { userResponse } = currentBlock;
      let evaluationResponses = userResponse?.userResponseList;

      if (evaluationResponses && evaluationResponses.length === 1 && evaluationResponses[0] === '')
        evaluationResponses = [];

      const payload = {
        contentBlockId: currentBlock.cbId,
        dctId: currentBlock.dctId,
        evaluationResponses,
        providerNarrative: userResponse?.providerNarrative || '',
      };
      try {
        const { data } = await updateEvaluationContext(payload, {
          appointmentId: appointment.appointmentId,
          evaluationId: currentEvaluation.id,
        });
        if (data.errors) {
          console.warn(data.errors);
        } else {
          list.transformEvaluation(data);
          dispatch(
            conversationActionCreators.evaluationContextUpdated({
              evaluationContext: data,
            }),
          );
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const submitFullPageDCT = async () => {
    if (currentBlock && currentBlock?.evaluationResponses) {
      const { evaluationResponses } = currentBlock;

      const payload = {
        appointmentId: appointment.appointmentId,
        dctId: currentBlock.dctId,
        evaluationId: currentEvaluation.id,
        evaluationResponses,
      };
      try {
        const { data } = await updateContextForFullPageDCT(payload);
        if (data.errors) {
          console.warn(data.errors);
        } else {
          list.transformEvaluation(data);
          dispatch(
            conversationActionCreators.evaluationContextUpdated({
              evaluationContext: data,
            }),
          );
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const updateEvaluationStatus = async () => {
    const payload = {
      evaluationUpdatedStatus: 'COMPLETED',
      evaluationId: currentEvaluation.id,
      remarks: 'Evaluation completed',
    };
    const { data } = await updateAppointmentEvaluationStatus(payload, {
      appointmentId: appointment?.appointmentId,
    });
    getEvaluationProgressList();
    if (data.errors) {
      console.warn(data.errors);
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: data.errors?.[0]?.endUserMessage || 'Something went wrong',
        }),
      );
    }
  };

  const respondAndGoNext = (fullPage = false) => {
    setNextInProgress(true);
    if (fullPage) void submitFullPageDCT();
    else void submitAnswer();
  };

  const nextQuestion = (leaveUnanswered = false) => {
    const isRequiredAndUnanswered = block => {
      const skipTypes = ['exercise', 'education'];
      return (
        block.required &&
        (!block.userResponse || block.userResponse?.userResponseList.length === 0) &&
        !skipTypes.includes(block.cbType)
      );
    };

    if (!currentBlock) return;

    if (currentBlock.cbType !== 'dct') {
      if (isRequiredAndUnanswered(currentBlock) && !leaveUnanswered) setShowLeaveUnansweredModal(true);
      else if (currentBlock.userResponse) respondAndGoNext();
      else setNextBlock(currentBlock, head, true);
      return;
    }

    if (currentBlock.cbType === 'dct' && currentBlock.fullPage) {
      const unansweredRequiredChildren = currentBlock.children.filter(isRequiredAndUnanswered);

      if (unansweredRequiredChildren.length > 0 && !leaveUnanswered) setShowLeaveUnansweredDCTModal(true);
      else if (currentBlock.evaluationResponses.length > 0) respondAndGoNext(true);
      else setNextBlock(currentBlock, head, true);
      return;
    }

    if (currentBlock.next) setCurrentBlock(currentBlock.next);
    else setShowEvaluationReview(true);
  };

  const openHotkeysModal = e => {
    e.preventDefault();
    e.target.blur();
    setIsHotkeysModalOpen(true);
  };

  const closeHotkeysModal = () => setIsHotkeysModalOpen(false);

  const handleKeyboardShortcuts = e => {
    if (
      showCompletedEvaluation ||
      showLeaveUnansweredModal ||
      showLeaveUnansweredDCTModal ||
      isHotkeysModalOpen ||
      routeState !== RouteState.IN_PROGRESS ||
      textInputInFocus
    ) {
      return;
    }
    if (e.key === ' ' && !e.shiftKey) {
      e.preventDefault();
      if (showEvaluationReview) {
        completeEvaluation();
      } else if ((currentBlock.cbType === 'exercise' || currentBlock.cbType === 'education') && isRead) {
        updateValues({ id: currentBlock.cbId, narrative: '', value: ['true'] });
        nextQuestion();
      } else {
        nextQuestion();
      }
    }

    if (e.key === ' ' && e.shiftKey) {
      setPreviousBlock(currentBlock);
    }

    if (e.key === 'ArrowUp') {
      if (currentBlock.cbType === 'dct' && currentBlock.fullPage) {
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1);
        } else if (focusedChildIndex > 0) {
          // Move focus to the previous child block
          setFocusedChildIndex(focusedChildIndex - 1);
          const prevChild = currentBlock.children.filter(
            block => block.cbType !== 'education' && block.cbType !== 'exercise',
          )[focusedChildIndex - 1];
          setSelectedIndex(prevChild.choices?.length - 1);
        }
        return;
      }

      if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
    }

    if (e.key === 'ArrowDown') {
      if (currentBlock.cbType === 'dct' && currentBlock.fullPage) {
        const childBlocks = currentBlock.children.filter(
          block => block.cbType !== 'education' && block.cbType !== 'exercise',
        );

        const child = childBlocks[focusedChildIndex];

        if (selectedIndex < child.choices?.length - 1) {
          setSelectedIndex(selectedIndex + 1);
        } else if (focusedChildIndex < childBlocks.length - 1) {
          // Move focus to the next child block
          setFocusedChildIndex(focusedChildIndex + 1);
          setSelectedIndex(0); // Reset index for new child
        }
        return;
      }

      if (selectedIndex < currentBlock.choices.length - 1) setSelectedIndex(selectedIndex + 1);
    }

    if (e.key === 'ArrowRight') {
      if (selectedIndex > -1 || (currentBlock && currentBlock.cbType === 'rating-scale')) {
        setRightTrigger(prev => !prev);
      }
    }

    if (e.key === 'ArrowLeft') {
      if (selectedIndex > -1 || (currentBlock && currentBlock.cbType === 'rating-scale')) {
        setLeftTrigger(prev => !prev);
      }
    }

    if (e.key === 'K' && e.shiftKey) {
      openHotkeysModal(e);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardShortcuts);
    return () => {
      window.removeEventListener('keydown', handleKeyboardShortcuts);
    };
  }, [
    showCompletedEvaluation,
    showEvaluationReview,
    showLeaveUnansweredModal,
    showLeaveUnansweredDCTModal,
    isHotkeysModalOpen,
    currentBlock,
    routeState,
    textInputInFocus,
    selectedIndex,
    focusedChildIndex,
    leftTrigger,
    rightTrigger,
  ]);

  const getQuestionSectionsByType = (evaluationDtoList: any[]) => {
    const questionSections = [];
    const dctIds = [];
    evaluationDtoList?.forEach(evaluationDto => {
      if (evaluationDto?.cbType) {
        if (evaluationDto?.cbType === 'dct') {
          if (dctIds?.includes(evaluationDto.dctId)) {
            questionSections.forEach(section => {
              if (evaluationDto?.dctId === section?.dctId) {
                section.items = evaluationDto?.dctContentBlockList?.filter(item => item.cbType !== 'dct');
              }
            });
          } else {
            dctIds.push(evaluationDto?.dctId);
            questionSections.push({
              title: evaluationDto?.text,
              isDct: true,
              score: 0,
              items: evaluationDto?.dctContentBlockList?.filter(item => item.cbType !== 'dct'),
              dctId: evaluationDto?.dctId,
              dctScore: evaluationDto?.dctScore,
              isDCTScoreable: evaluationDto?.isDCTScoreable,
              fullPage: evaluationDto?.fullPage,
              isAttemptable: evaluationDto?.isAttemptable,
              displayLogics: evaluationDto?.displayLogics,
              cbId: evaluationDto?.cbId,
              responderType: evaluationDto?.responderType,
              description: evaluationDto?.description,
              prompt: evaluationDto?.prompt,
            });
          }
        } else if (evaluationDto?.cbType !== 'dct') {
          questionSections.push({
            title: evaluationDto?.text,
            type: 'CB',
            score: 0,
            isAttemptable: evaluationDto?.isAttemptable,
            displayLogics: evaluationDto?.displayLogics,
            cbId: evaluationDto?.cbId,
            items: [
              {
                ...evaluationDto,
                description: '',
                type: evaluationDto?.cbType,
                isDone: evaluationDto?.isDone || false,
              },
            ],
          });
        }
      }
    });

    return questionSections;
  };

  const onCloseEvaluationDetail = () => {
    getEvaluationProgressList();
    setRouteState(RouteState.INITIAL);
  };

  const renderLeftSection = () => {
    switch (routeState) {
      case RouteState.INITIAL:
        return (
          <EvaluationsV2
            appointmentId={appointment?.appointmentId}
            onSelectEvaluation={evaluation => {
              if (evaluation.status === 'REJECTED') {
                setShowRejectedEvaluation(true);
                evaluationSelected(evaluation);
              } else if (evaluation.status === 'COMPLETED') {
                setShowCompletedEvaluation(true);
                evaluationSelected(evaluation);
              } else {
                setShowCompletedEvaluation(false);
                setShowEvaluationReview(false);
                evaluationSelected(evaluation);
              }
            }}
            showAddEvaluation={showAddEvaluation}
            setShowAddEvaluation={setShowAddEvaluation}
            updateEvaluationProgress={getEvaluationProgressList}
            setToastProperties={setToastProperties}
          />
        );
      case RouteState.IN_PROGRESS:
        return (
          <EvaluationDetail
            listHelper={list}
            currentBlock={currentBlock}
            currentEvaluation={currentEvaluation}
            closeHotkeysModal={closeHotkeysModal}
            openHotkeysModal={e => openHotkeysModal(e)}
            isHotkeysModalOpen={isHotkeysModalOpen}
            showAllEvaluations={() => {
              setRouteState(RouteState.INITIAL);
            }}
            setCurrentBlock={block => {
              setSelectedIndex(-1);
              setFocusedChildIndex(0);
              setCurrentBlock(block);
              setShowEvaluationReview(false);
            }}
            onClose={onCloseEvaluationDetail}
            setToastProperties={setToastProperties}
            appointmentId={appointment?.appointmentId}
            evaluationContext={evaluationContext}
            isEndedSession={isEndedSession}
          />
        );
      default:
        return null;
    }
  };

  const showDefaultScreen =
    isEndedSession &&
    (selectedSession === FilterSessions[0].type ||
      selectedSession === FilterSessions[2].type ||
      selectedSession === FilterSessions[3].type);

  const onShareScreenClick = () => {
    setShowShareScreenSelection(true);
  };

  const onStopSharingClick = () => {
    if (showShareScreenSelection) {
      setShowShareScreenSelection(false);
    }
  };
  /**
   * @Name toggleVideCall
   * @description This method is used to toggle video call in session .
   */
  const toggleVideCall = () => {
    console.log('toggle called');
    setPublishVideo(!publishVideo);
  };
  /**
   * @Name toggleMic
   * @description This method is used to toggle mic in session .
   */
  const toggleMic = () => {
    setPublishAudio(!publishAudio);
  };

  /**
   * @Name onFullScreen
   * @description This method is used to handle full screen click event .
   */
  const onFullScreen = () => {
    console.log('onFullScreen');
  };

  const onSubmitNewSchedule = (payload: INewSchedulePayload) => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed: boolean /* , isInstantSession: boolean, appointmentId: string */) => {
          if (isSucceed) {
            setSchedulesDrawerOpen(false);
            onChangeView(FilterSessions[0].type);
          }
        },
      }),
    );
  };

  /**
   * @Name onViewEvaluation
   * @description This method is used to handle View Evaluation Event .
   */
  const onViewEvaluation = () => {
    setShowRejectedEvaluation(false);
    setShowCompletedEvaluation(false);
    setRouteState(RouteState.INITIAL);
  };

  useEffect(() => {
    if (selectedSession === 'default') {
      setShowRightPanel(false);
    }

    if (isEndedSession && selectedSession === FilterSessions[2].type) {
      setChatsDrawerOpen(true);
    } else if (isEndedSession && selectedSession === FilterSessions[3].type) {
      setSchedulesDrawerOpen(true);
    }
  }, [selectedSession]);

  const isEvaluationView = selectedSession === sessionFilterList[1]?.type;
  const isEvaluationDetailView = isEvaluationView && !!currentBlock && routeState === RouteState.IN_PROGRESS;

  const checkMediaPermissions = async () => {
    try {
      const cameraPermission = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });
      const microphonePermission = await navigator.permissions.query({
        name: 'microphone' as PermissionName,
      });
      const videoGranted = cameraPermission.state === 'granted';
      const audioGranted = microphonePermission.state === 'granted';
      if (videoGranted && audioGranted) {
        console.log('Video and audio permissions granted.');
        return true;
      }
      alert('Please enable both camera and microphone permissions.');
      return false;
    } catch (error) {
      console.error('Error checking media permissions:', error);
      alert('An error occurred while checking media permissions.');
      return false;
    }
  };

  /**
   * @Name publisherEventHandlers
   * @description Publisher event handlers details
   */
  const publisherEventHandlers = {
    error: (error: any) => {
      console.log(`There was an error with the publisher: ${error}`);
    },
    streamCreated: (event: any) => {
      console.log('Publisher stream created', event);
    },
    streamDestroyed: (event: any) => {
      console.log(`Publisher stream destroyed because: ${event}`);
    },
  };
  /**
   * @Name sessionEventHandlers
   * @description Session event handlers details
   */
  const sessionEventHandlers = {
    streamCreated: (event: any) => {
      console.log('Stream created event', event);
    },
    signal: (event: any) => {
      if (event.type === 'session-ended' || event.type === 'signal:session-ended') {
        isSessionEndedRef.current = true;
        setIsEndedSession(true);
        onChangeView(sessionFilterList[0]?.type);
      }
    },
    streamDestroyed: (event: any) => {
      onStartSession(false);
      console.log(`Stream destroyed ${event}`);
    },
    connectionDestroyed: (event: any) => {
      onStartSession(false);
      console.log(`Connection destroyed ${event}`);
      if (!isSessionEndedRef?.current) {
        dispatch(
          showSnackbar({
            snackType: 'error',
            snackMessage: 'Member has left the session.',
          }),
        );
      }
      // setIsWaiting(true);
    },
    sessionConnected: async (event: any) => {
      const mediaStream = await checkMediaPermissions();
      if (mediaStream) {
        console.log(`sessionConnected: ${event}`);
      }
    },
    sessionDisconnected: (event: any) => {
      console.log(`sessionDisconnected: ${event}`);
    },
  };
  /**
   * @Name subscriberEventHandlers
   * @description Subscriber event handler details
   */
  const subscriberEventHandlers = {
    error: (error: any) => {
      console.log(`There was an error with the subscriber: ${error}`);
    },
    connected: async (event: any) => {
      const mediaStream = await checkMediaPermissions();
      if (mediaStream) {
        console.log(`Subscriber connected to the stream`);
        setSessionStarted(true);
        setIsWaiting(false);
        onStartSession(true);
        console.log(event);
      }
    },
    videoDisabled: event => {
      console.log('Subscriber video disabled!', event);
      setMemberVideoEnable(false);
    },
    videoEnabled: event => {
      setMemberVideoEnable(true);
      console.log('Subscriber video enabled!', event);
    },
    disconnected: (event: any) => {
      console.log(`Subscriber disconnected from the stream`);
      console.log(event);
    },
  };

  const renderDefaultScreen = () => (
    <Box className={classes.mainContent}>
      <Box display="flex" justifyContent="space-between" className={classes.waitingScreen}>
        <Box className={classes.boxLeft}>
          <WaitingHeader appointment={appointment} isCompleted={isEndedSession} />
          <Priorities
            hideNotes
            onChangeView={() => setChatsDrawerOpen(true)}
            currentAppointment={appointment}
          />
        </Box>
        <AppointmentInfo
          appointment={appointment}
          onEndSession={onEndSession}
          isCompleted={isEndedSession}
          startInPersonSession={startInPersonSession}
          showInPersonSession={showInPersonSession}
        />
      </Box>
      <Drawer
        open={chatsDrawerOpen}
        onClose={() => setChatsDrawerOpen(false)}
        variant={drawerType.NORMAL}
        className={classes.chatDrawer}
      >
        <Chats
          isEndedSession={isEndedSession}
          onClose={() => setChatsDrawerOpen(false)}
          careTeamListIds={careTeamListIds}
          appointment={appointment}
          sessionStarted={sessionStarted}
        />
      </Drawer>
      {schedulesDrawerOpen && (
        <AddSchedule
          isOpen={schedulesDrawerOpen}
          onClose={() => setSchedulesDrawerOpen(false)}
          onSubmit={onSubmitNewSchedule}
          memberId={appointment?.participantId}
          appointment={appointment}
          isVideoSession={false}
          // providerId={chatType === 'providers' ?  selectedContact?.id : null}
        />
      )}
    </Box>
  );

  return (
    <>
      <LeaveUnansweredModal
        open={showLeaveUnansweredModal}
        onClose={() => setShowLeaveUnansweredModal(false)}
        onConfirm={() => {
          setShowLeaveUnansweredModal(false);
          nextQuestion(true);
        }}
      />
      {showLeaveUnansweredDCTModal && (
        <LeaveUnansweredModal
          dct
          open={showLeaveUnansweredDCTModal}
          onClose={() => setShowLeaveUnansweredDCTModal(false)}
          onConfirm={() => {
            setShowLeaveUnansweredDCTModal(false);
            nextQuestion(true);
          }}
        />
      )}
      <ToastMessage
        show={toastProperties.status}
        snackType={toastProperties.type as AlertColor}
        snackMessage={toastProperties.message}
        clearSnackbarAction={() => {
          setToastProperties({ message: '', type: toastProperties.type, status: false });
        }}
      />
      {(isWaiting || showDefaultScreen) && renderDefaultScreen()}
      <Box
        className={cx(
          isWaiting
            ? classes.hiddenContainer
            : !isInPersonSessionCall
            ? classes.container
            : classes.inPersonContainer,
          selectedSession === FilterSessions[1].type ? classes.dashboardScroll : '',
        )}
      >
        <Box
          className={cx({
            [classes.initialEvaluationPanel]:
              isEvaluationView && !isInPersonSessionCall && routeState === RouteState.INITIAL,
            [classes.inProgressEvaluationPanel]:
              isEvaluationView &&
              !isInPersonSessionCall &&
              !isEndedSession &&
              routeState === RouteState.IN_PROGRESS,
            [classes.inProgressEvaluationPanelWidth]:
              isEvaluationView &&
              !isInPersonSessionCall &&
              isEndedSession &&
              routeState === RouteState.IN_PROGRESS,
            [classes.priorities]: selectedSession === FilterSessions[1].type,
            [classes.rightPanel]: !isInPersonSessionCall,
          })}
        >
          {isEvaluationView && !isInPersonSessionCall && renderLeftSection()}
          {selectedSession === FilterSessions[1].type && (
            <Box sx={{ padding: 5, background: colors.neutral50 }}>
              <Priorities
                onChangeView={onChangeView}
                hideNotes={isWaiting || isInPersonSessionCall}
                currentAppointment={appointment}
                isInPersonSessionCall={isInPersonSessionCall}
              />
            </Box>
          )}
        </Box>
        <Box className={isEndedSession ? classes.hiddenContainer : classes.videoContainer}>
          <TopbarView
            isSharing={showShareScreenSelection}
            isVideoCall={publishVideo}
            isAudio={publishAudio}
            shareScreen={selectedScreenSharing}
            onShareScreenClick={onShareScreenClick}
            onStopSharingClick={onStopSharingClick}
            onEndSession={onEndSession}
            toggleVideCall={toggleVideCall}
            toggleMic={toggleMic}
            appointment={appointment}
            inPersonSession={isInPersonSessionCall}
          />
          {telesessionId && (
            <Box
              id="room-container"
              className={cx(classes.video, {
                [classes.hiddenContainer]: isInPersonSessionCall,
              })}
            >
              <OTSession
                apiKey={OPENTOK_APIKEY}
                sessionId={telesessionId}
                token={telesessionToken}
                eventHandlers={sessionEventHandlers}
              >
                <div className={classes.subscriberVideo}>
                  <OTStreams>
                    <OTSubscriber
                      properties={{ height: 'calc(100vh - 84px)', width: '100vh' }}
                      eventHandlers={subscriberEventHandlers}
                    />
                  </OTStreams>
                </div>
                {!memberVideoEnable && !isEvaluationDetailView && (
                  <Box className={classes.noVideo}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                      <Avatar
                        src={appointment?.participantImage}
                        name={appointment?.participantName}
                        size={128}
                        variant={avatarType.CIRCLE}
                      />
                      <Heading level={headingLevel.M} weight={fontWeight.BOLD} className={classes.username}>
                        {appointment?.participantName?.split(' ').slice(0, -1).join(' ')}
                      </Heading>
                      <Text className={classes.fullname} weight={fontWeight.SEMI_BOLD}>
                        {appointment?.participantName}
                      </Text>
                    </Box>
                  </Box>
                )}
                <BottomView
                  shareScreen={selectedScreenSharing}
                  isVideoCall={publishVideo}
                  onFullScreen={onFullScreen}
                  publisher={
                    <OTPublisher
                      properties={{ publishVideo, publishAudio, width: 200, height: 114 }}
                      eventHandlers={publisherEventHandlers}
                    />
                  }
                  screen={
                    showShareScreenSelection && (
                      <OTPublisher
                        properties={{ videoSource: 'screen', width: 200, height: 114 }}
                        eventHandlers={publisherEventHandlers}
                      />
                    )
                  }
                />
              </OTSession>
            </Box>
          )}
        </Box>
        <Box
          className={cx(classes.rightPanel, {
            [classes.showRightPanel]: showRightPanel,
            [classes.chatPanel]: selectedSession === FilterSessions[2].type,
            // [classes.notePanel]: selectedSession === FilterSessions[3].type,
            [classes.schedulePanel]: selectedSession === FilterSessions[3].type,
            [classes.checklistPanelNoVideo]: selectedSession === sessionFilterList[0]?.type && isEndedSession,
            [classes.checklistPanel]: selectedSession === sessionFilterList[0]?.type && !isEndedSession,
            [classes.evaluationRightPanel]: isEvaluationDetailView,
            [classes.evaluationRightPanelNoVideo]: isEndedSession && isEvaluationDetailView,
            // [classes.rightPanelNoVideo]: isEndedSession || (!isEvaluationDetailView && !publishVideo),
            [classes.inPersonRightPanel]: isInPersonSessionCall,
          })}
        >
          {selectedSession === FilterSessions[2].type && !isSignOff && (
            <Chats onClose={onCloseSession} careTeamListIds={careTeamListIds} appointment={appointment} />
          )}
          {/* {selectedSession === FilterSessions[3].type && ( */}
          {/*  <Notes onClose={onCloseSession} notes={notes} setNotes={setNotes} /> */}
          {/* )} */}
          {selectedSession === FilterSessions[3].type && (
            <AddSchedule
              isOpen={selectedSession === FilterSessions[3].type}
              onClose={() => {
                onChangeView(FilterSessions[0].type);
              }}
              onSubmit={onSubmitNewSchedule}
              memberId={appointment?.participantId}
              appointment={appointment}
              isVideoSession={false}
            />
          )}
          {selectedSession === sessionFilterList[0]?.type && (
            <Checklist
              appointment={appointment}
              onSelectedEvaluation={evaluationSelected}
              onSelectedEvaluationSection={setSelectedEvaluationSectionItem}
              showEvaluation={() => onChangeView(sessionFilterList[1].type)}
              showSchedule={() => onChangeView(FilterSessions[3].type)}
              setShowSignOff={setShowSignOff}
              isSession
              isEndedSession={isEndedSession}
              getEvaluationProgressListt={getEvaluationProgressList}
            />
          )}
          {isEvaluationView && isInPersonSessionCall && (
            <ProvideEvaluationHead>
              <InPersonSession
                appointmentId={appointment?.appointmentId}
                updateEvaluationProgress={getEvaluationProgressList}
                setToastProperties={setToastProperties}
                initialEvaluation={initialEvaluation}
              />
            </ProvideEvaluationHead>
          )}
          {isEvaluationDetailView &&
            !showEvaluationReview &&
            !showCompletedEvaluation &&
            !showRejectedEvaluation && (
              <EvaluationRightSection
                currentBlock={currentBlock}
                isDisablePrevious={currentBlock?.isFirst}
                setInputFocused={setTextInputInFocus}
                appointment={appointment}
                listHelper={list}
                currentEvaluation={currentEvaluation}
                setShowEvaluationReview={setShowEvaluationReview}
                setCurrentBlock={block => setCurrentBlock(block)}
                selectedIndex={selectedIndex}
                childIndex={focusedChildIndex}
                rightTrigger={rightTrigger}
                leftTrigger={leftTrigger}
                setIsRead={v => setIsRead(v)}
                isRead={isRead}
                onClickNext={() => {
                  nextQuestion();
                }}
                onClickPrevious={() => {
                  setPreviousBlock(currentBlock);
                }}
                skipNonRequiredCB={() => {
                  console.log('Skip clicked');
                }}
              />
            )}
          {isEvaluationDetailView && showEvaluationReview && (
            <EvaluationReview
              onClickComplete={completeEvaluation}
              onClickPrevious={() => {
                setShowEvaluationReview(false);
              }}
              selectedEvaluation={selectedEvaluationItem}
            />
          )}
          {isEvaluationDetailView && showCompletedEvaluation && (
            <EvaluationCompleted onClickView={onViewEvaluation} selectedEvaluation={selectedEvaluationItem} />
          )}
          {isEvaluationDetailView && showRejectedEvaluation && (
            <EvaluationRejected onClickView={onViewEvaluation} selectedEvaluation={selectedEvaluationItem} />
          )}
        </Box>
      </Box>
    </>
  );
};

export { VideoCallScreen };
